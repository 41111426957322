import { FRONTEND_DATA } from "../../../globals";
import { 
	retrieveSequentualNodesEffectivelyContainedByRange,
} from "../helpers";
import { helpers } from "@cargo/common";

export default {

	defaultColumns: {
		gutter: 1,
		columnSizes: [null, null]
	},

	requiresTextInRange: false,
	requiresUncollapsedRange: false,
	requiresActiveRange: true,
	suppressWindowAfterExecution: true,

	execute: function(){

		var range = FRONTEND_DATA.contentWindow.CargoEditor.getActiveRange();

		if(range == undefined){
			return
		}

		if(this.getState(range).isApplied){
			return
		}

		FRONTEND_DATA.contentWindow.CargoEditor.mutationManager.execute(()=>{

			const state = window.store.getState();
		    const sessionState = state.adminState.session;
		    const isMobile = state.frontendState.isMobile;

		    let columnSettings = sessionState['last-column-settings'] ?? {...this.defaultColumns};
			let columnSet = FRONTEND_DATA.contentWindow.document.createElement('column-set');

			columnSet._newColumnSet = true;

			if (columnSettings.gutter){
				columnSet.setAttribute('gutter', columnSettings.gutter)			
			}

			if( isMobile ){
				columnSet.setAttribute('mobile-stack', 'false');
			} else {

				if (columnSettings['mobile-stack'] != undefined ){
					columnSet.setAttribute('mobile-stack', columnSettings['mobile-stack'])
				}

				if (columnSettings['mobile-hide-empty'] != undefined ){
					columnSet.setAttribute('mobile-hide-empty', columnSettings['mobile-hide-empty'])
				}
			}

			if (columnSettings.units && parseInt(columnSettings.units) != 12){
				columnSet.setAttribute('units', columnSettings.units)
			}

			let columnsToCreate = columnSettings.columnSizes?.length ?? 1;
			
			if( isMobile ){
				columnsToCreate = Math.min(columnsToCreate, 3);
			}

			for(let i = 0; i < columnSettings.columnSizes?.length; i++){
				let columnUnit = FRONTEND_DATA.contentWindow.document.createElement('column-unit');
				if( columnSettings.columnSizes[i] ){
					columnUnit.setAttribute('span', columnSettings.columnSizes[i])
				}
				columnSet.appendChild(columnUnit);
			}
		

			if(range.collapsed === false) {
				
				range = FRONTEND_DATA.contentWindow.CargoEditor.helpers.growRangeToWidestBoundaries(range);		


				let frag = FRONTEND_DATA.contentWindow.CargoEditor.helpers.advancedSplitRange(range, { preventShrinkingRange: true }).extractContents();
				while(frag.childNodes.length > 0) {
					columnSet.children[0].appendChild(frag.childNodes[0]);
				}

			}

			range.insertNode(columnSet);

			// setTimeout used because Safari doesn't like setting the range directly afterward
			setTimeout(()=>{

				const firstCol = columnSet.querySelector('column-unit');

				if(firstCol) {

					// select node contents and collapse to end
					range.selectNodeContents(firstCol);
					range.collapse(false);

					FRONTEND_DATA.contentWindow.CargoEditor.helpers.setActiveRange(range);

				}

			}, 100)
			

		});
	},
	priority: 1,
	getState: (range)=>{

		const columnNodes = retrieveSequentualNodesEffectivelyContainedByRange("COLUMN-SET");
		let closestGallery = null;
		if( range ){
			closestGallery = helpers.closest(range.commonAncestorContainer, function(el){
				return el && el.nodeName.startsWith('GALLERY-')
			})
		}
		


		return {
			isApplied: false,
			isAllowed: columnNodes.length === 0 && !closestGallery,
		};

	}
 
}